html {
  background-color: #f5e1e3;
}

@media print {
  html {
    background-color: unset;
  }

  #KitWidgetContainer {
    display: none !important;
  }
}

body {
  font-family: Graphik, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
}

.in-print-mode > *:not(#root) {
  display: none;
}

.in-print-mode #root > *:not(#print-editor) {
  display: none;
}

.in-print-mode #KitWidgetContainer > * {
  display: none !important;
}

.modal-root {
  position: fixed;
  z-index: 1000;
}

.right-sidebar-root {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}

/* Animations */

@keyframes rotate {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

/* Day Picker styles */

.DayPicker-wrapper {
  padding-bottom: 0 !important;
}

.kit-widget-container {
  display: none !important;
}

.kit-widget-container .widget-btn {
  display: none !important;
}

.kit-widget-container.is-open {
  display: flex !important;
  @media (max-width: 768px) {
    height: 100vh;
  }
}

.kit-widget-container.is-open .kit-widget-modal__close-btn {
  display: block!important;
}

.kit-widget-gallery__container {
  width: 96%;
  z-index: 9999 !important;
}

.kit-widget-gallery__body {
  width: 98% !important;
}

.kit-widget-message__error-block {
  @media(max-width: 768px) {
    top: 0 !important;
  }
}

.kit-widget-gallery img, .kit-widget-gallery svg {
  max-width: 100%;
}

.DayPickerInput-Overlay {
  top: 20px;
  right: -73px;
  left: auto !important;
  z-index: 10 !important;
}

.DayPicker-NavButton {
  width: 24px !important;
  height: 24px !important;
  top: 10px !important;
  right: 16px !important;
  background-size: 100% !important;
}

.DayPicker-NavButton--prev {
  margin-right: 0 !important;
  left: 16px !important;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjkxMTQgMTkuMjA4NEw5IDEyLjI5N0wxNS45MTE0IDUiIHN0cm9rZT0iIzRBNEE0QSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=") !important;
}

.DayPicker-NavButton--next {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkgMTkuMjA4NEwxNS45MTE0IDEyLjI5N0w5IDUiIHN0cm9rZT0iIzRBNEE0QSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=") !important;
}

.DayPicker-Month {
  display: block !important;
  margin: 0 !important;
}

.DayPicker-Caption {
  display: block !important;
  text-align: center !important;
  text-transform: capitalize !important;
  line-height: 17px;
  padding: 16px !important;
  margin-bottom: 0 !important;
  font: 17px Graphik, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

.DayPicker-Weekdays {
  display: block !important;
  margin-top: 0 !important;
  border-bottom: 1px solid rgba(151, 151, 151, 0.3);
}

.DayPicker-WeekdaysRow {
  display: flex !important;
  justify-content: space-between;
  padding: 0 7px;
}

.DayPicker-Weekday {
  display: inline-flex !important;
  padding: 14px 9px !important;
  justify-content: center;
  align-items: center;
  width: 40px !important;
  box-sizing: border-box;
  text-transform: uppercase;
  color: #262626 !important;
}

.DayPicker-Body {
  display: block !important;
  padding: 0 7px 7px !important;
}

.DayPicker-Week {
  display: flex !important;
  justify-content: space-between;
}

.DayPicker-Week:not(:last-child) {
  margin-bottom: 8px;
}

.DayPicker-Day {
  display: inline-flex !important;
  width: 40px !important;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px !important;
  font-size: 16px;
  line-height: 23px;
  padding: 8px 12px !important;
}

.DayPicker-Day--today {
  color: #c6007f !important;
  font-weight: normal !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #c6007f !important;
  /*margin: -4px -4px !important;*/
  /*width: 48px !important;*/
}

.DayPicker-Day--today.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  color: #fff !important;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: #f3f3f3 !important;
}

/* Day Picker styles end */

/* Calendar styles */

.react-calendar.date-input-calendar {
  border-radius: 3px;
  overflow: hidden;
  border: none;
  font-family: Graphik, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  width: 316px;
  max-width: 316px;
}

.react-calendar.date-input-calendar .react-calendar__navigation {
  margin-bottom: 0;
}

.react-calendar.date-input-calendar .react-calendar__navigation__label__labelText {
  font-family: Graphik, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  font-size: 17px;
  color: #262626;
  font-weight: 500;
  text-transform: capitalize;
}

.react-calendar.date-input-calendar .react-calendar__navigation__prev2-button,
.react-calendar.date-input-calendar .react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar.date-input-calendar .react-calendar__tile {
  font-family: Graphik, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 0;
}

.react-calendar.date-input-calendar .react-calendar__tile abbr {
  font-family: Graphik, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  font-size: 16px;
  line-height: 24px;
}

.react-calendar.date-input-calendar .react-calendar__year-view .react-calendar__tile,
.react-calendar.date-input-calendar .react-calendar__decade-view .react-calendar__tile,
.react-calendar.date-input-calendar .react-calendar__century-view .react-calendar__tile {
  padding: 24px 0;
  text-transform: capitalize;
}

.react-calendar.date-input-calendar .react-calendar__century-view .react-calendar__tile {
  font-size: 14px;
}

.react-calendar.date-input-calendar .react-calendar__month-view__weekdays__weekday abbr {
  font-family: Graphik, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  font-size: 13px;
  font-weight: 400;
  text-decoration: none;
}

.react-calendar.date-input-calendar .react-calendar__month-view__weekdays {
  border-bottom: 1px solid rgba(151, 151, 151, 0.3);
  padding: 0 10px;
}

.react-calendar.date-input-calendar .react-calendar__month-view__days {
  padding: 0 10px;
}

.react-calendar.date-input-calendar .react-calendar__tile--now {
  color: #c6007f;
  background: #fff;
}

.react-calendar.date-input-calendar .react-calendar__tile--now:disabled {
  background: #f0f0f0;
}

.react-calendar.date-input-calendar .react-calendar__month-view__days__day--weekend {
  color: #262626;
}

.react-calendar.date-input-calendar .react-calendar__tile--active,
.react-calendar.date-input-calendar .react-calendar__tile--hasActive {
  background: #c6007f;
  color: #fff;
  border-radius: 3px;
}

.react-calendar.date-input-calendar .react-calendar__tile--active:disabled {
  border-radius: 0;
  color: #c6007f;
}

.react-calendar.date-input-calendar .react-calendar__month-view__days__day--neighboringMonth {
  color: #262626;
  opacity: 0.6;
}

.react-calendar.date-input-calendar .react-calendar__tile:enabled:hover,
.react-calendar.date-input-calendar .react-calendar__tile:enabled:focus {
  background: rgba(151, 151, 151, 0.2);
  border-radius: 3px;
}

.react-calendar.date-input-calendar .react-calendar__tile--now:enabled:hover,
.react-calendar.date-input-calendar .react-calendar__tile--now:enabled:focus {
  background: rgba(151, 151, 151, 0.2);
}

.react-calendar.date-input-calendar .react-calendar__tile--active:enabled:hover,
.react-calendar.date-input-calendar .react-calendar__tile--active:enabled:focus {
  background: #c6007f;
}

.react-calendar.date-input-calendar .react-calendar__tile--hasActive:enabled:hover,
.react-calendar.date-input-calendar .react-calendar__tile--hasActive:enabled:focus {
  background: #c6007f;
}

.react-calendar.date-input-calendar
  .react-calendar__month-view__days__day--neighboringMonth:enabled:hover,
.react-calendar.date-input-calendar
  .react-calendar__month-view__days__day--neighboringMonth:enabled:focus,
.react-calendar.date-input-calendar .react-calendar__navigation button:enabled:hover,
.react-calendar.date-input-calendar .react-calendar__navigation button:enabled:focus {
  background: transparent;
}

/* Slick styles */

.slick-slider.slick-initialized {
  position: initial;
  width: 100%;
}

.slick-slide:not(:last-child) {
  margin-right: 20px;
}

.slick-prev,
.slick-next {
  z-index: 9;
}

.slick-prev {
  left: 0 !important;
}

.slick-next {
  right: 0 !important;
}

.slick-list,
.slick-track {
  height: 100%;
}

.slick-initialized .slick-slide > div {
  height: 100%;
  display: flex;
}

.general-schedule-slider .slick-list {
  margin: 0 20px;
}

.general-schedule-slider .slick-track {
  margin: 0 48px;
}

/* Slick styles end */

/* Toasts */

@keyframes toast-anim-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes toast-anim-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes toast-anim-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media print {
  .toaster {
    display: none;
  }
}

/* Toasts end */

/* Preloader animation */

@keyframes preloader-animation {
  0% {
    transform: translateX(-100%);
  }
  80% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Preloader animation end */

.Spreadsheet {
  background: transparent !important;
}

.Spreadsheet-full .Spreadsheet__table {
  table-layout: auto;
  width: 100%;
}

.Spreadsheet.Spreadsheet-hospital .Spreadsheet__active-cell--edit {
  min-width: 150px !important;
  width: unset !important;
}

.Spreadsheet .Spreadsheet__active-cell {
  border: 1px solid transparent;
  background-color: rgb(198 0 127 / 5%);
}

.Spreadsheet .Spreadsheet__floating-rect--selected {
  border-color: rgb(198 0 127 / 0%);
  background: rgb(198 0 127 / 5%);
}

.Spreadsheet .Spreadsheet__active-cell:not(.Spreadsheet__active-cell--edit) {
  background: rgb(198 0 127 / 5%);
}

.Spreadsheet .Spreadsheet__active-cell--edit {
  box-shadow: none;
  width: 300px !important;
  min-height: 44px;
  margin-top: -1px;
}

.Spreadsheet.Spreadsheet-manipulations .Spreadsheet__active-cell--edit {
  min-width: 100px !important;
  width: unset !important;
}

.Spreadsheet-full .Spreadsheet__table {
  table-layout: auto;
  width: 100%;
}

/* .Spreadsheet .Spreadsheet__floating-rect--selected {
  background: none !important;
  border: none !important;
} */

.Spreadsheet .Spreadsheet__header,
.Spreadsheet .Spreadsheet__data-viewer,
.Spreadsheet .Spreadsheet__data-editor input {
  padding: 0;
}

.table-100 {
  width: 100%;
}

/* CALENDAR */
.react-calendar__month-view__weekdays__weekday {
  color: #c1c1c1;
}

.react-calendar.date-input-calendar .react-calendar__tile--active,
.react-calendar.date-input-calendar .react-calendar__tile--hasActive {
  border-radius: 50%;
  padding: 10.5px 0;
}

.react-calendar.date-input-calendar .react-calendar__tile:enabled:hover,
.react-calendar.date-input-calendar .react-calendar__tile:enabled:focus {
  border-radius: 50%;
}

.react-calendar.date-input-calendar {
  width: 335px;
  max-width: 335px;

  @media (max-width: 768px) {
    width: auto;
  }
}

.react-calendar.date-input-calendar .react-calendar__month-view__weekdays {
  border-bottom: none;
}

.react-calendar__navigation {
  height: 58px !important;
}

.Spreadsheet__active-cell:-webkit-direct-focus,
.Spreadsheet__active-cell:focus-visible {
  outline: none !important;
  outline-color: transparent !important;
  outline-width: 0;
}

/* MUI Tooltips */

.MuiTooltip-popper {
  filter: drop-shadow(0 4px 4px rgba(0,0,0,0.2));
}

.MuiTooltip-tooltip {
  background: rgb(255 255 255 / 90%) !important;
  color: #000000 !important;
  font: 12px "Graphik" !important;
  padding: 8px !important;
  border-radius: 4px !important;
}

.MuiTooltip-arrow {
  color: rgb(255 255 255 / 90%) !important;
}
